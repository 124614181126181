<template>
  <section>
    <v-breadcrumbs
      class="text--secondary"
      large
      :items="breadcrumbsItems"
    ></v-breadcrumbs>
    <div class="d-flex align-center my-8">
      <div class="d-flex flex-column">
        <p class="mb-0">Proveedor</p>
        <p class="no-margin text--secondary">
          {{ proveedorInfo.nombre || proveedorInfo.ganador_obs }}
        </p>
      </div>
      <div class="d-flex mx-4" v-if="$route.params.tipo == 2">
        <v-btn @click="dialogProveedor = true" small dark color="secondary">
          Ver información
        </v-btn>
      </div>
    </div>
    <div class="my-8 py-8">
      <p>Listado de insumos</p>
      <InsumosTableComponent :headers="setInsumosHeader" :desdeUCP="true" />
    </div>
    <div class="my-8">
      <v-btn color="secondary" @click="regresar()" text>Volver</v-btn>
    </div>
    <v-dialog v-model="dialogProveedor" width="900">
      <v-card>
        <v-card-title class="text-h6 secondary--text">
          Perfil del proveedor
        </v-card-title>

        <v-card-text>
          <InformacionProvedor :providerData="providerData" />
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" outlined @click="dialogProveedor = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ChatComponent :idSolicitud="id_solicitud" modelo="SolicitudCompra" />
  </section>
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
import ChatComponent from "@/components/ChatComponent";
import InsumosTableComponent from "@/components/InsumosTableComponent.vue";
import InformacionProvedor from "../../components/InfomacionProveedorComponent.vue";
export default {
  name: "detalleDeInsumosProveedorView",
  components: {
    InsumosTableComponent,
    ChatComponent,
    InformacionProvedor,
  },
  data: () => ({
    breadcrumbsItems: [],
    dialogProveedor: false,
    providerData: {},
    headersCompraLinea: [
      {
        text: "Correlativo",
        value: "correlativo",
        align: "center",
      },
      {
        text: "Mercancía",
        align: "center",
        value: "nombre_origen_compra_en_linea",
      },
      {
        text: "Especificación",
        align: "center",
        value: "especificacion_compra_en_linea",
      },
      {
        text: "Unidad de medida",
        align: "center",
        value: "unidad_medida",
      },

      {
        text: "Total ($)",
        align: "center",
        value: "total",
      },
      {
        text: "Estado",
        align: "center",
        value: "estado",
      },
    ],
    headersConvenioMarco: [
      {
        text: "Correlativo",
        value: "correlativo",
        align: "center",
      },
      {
        text: "Mercancía",
        align: "center",
        value: "obs",
      },
      {
        text: "Especificación",
        align: "center",
        value: "presentacion",
      },
      {
        text: "Unidad de medida",
        align: "center",
        value: "unidad_medida",
      },

      {
        text: "Total ($)",
        align: "center",
        value: "total",
      },
      {
        text: "Estado",
        align: "center",
        value: "estado",
      },
      {
        text: "Acciones",
        value: "verDetalle",
        align: "center",
        sortable: false,
      },
    ],
  }),
  computed: {
    ...mapState("agregarInsumo", [
      "proveedorInfo",
      "id_solicitud",
      "selectedProvider",
    ]),
    ...mapState("comprasModule", ["correlativoSolicitud"]),

    setInsumosHeader() {
      const id = Number(this.$route.params.tipo);
      switch (id) {
        case 2:
          return this.headersConvenioMarco;
        case 3:
          return this.headersCompraLinea;
      }
    },
  },
  methods: {
    ...mapActions("agregarInsumo", ["getInsumosList"]),
    ...mapMutations("agregarInsumo", ["setGanadorFilter"]),
    regresar() {
      this.$router.push({
        name: "lista-ganadores",
        params: { tipo: Number(this.$route.params.tipo) },
      });
    },
    async fetchProviderData() {
      let response = await this.services.AgreementMarco.getProviderAgreement(
        this.selectedProvider
      );
      if (response?.status == 200) {
        this.providerData = response?.data;
      }
    },
  },
  created() {
    this.setGanadorFilter(this.proveedorInfo.id_ganador_obs);
    let props = {
      id_solicitud: this.id_solicitud,
      proveedor: this.$route.query?.proveedor,
    };
    this.getInsumosList(props);
    if (this.$route.params.tipo == 2) {
      this.fetchProviderData();
    }
    this.breadcrumbsItems = [
      {
        text: this.correlativoSolicitud,
      },
      {
        text: "Proveedor",
      },
      {
        text: this.proveedorInfo?.ganador_obs,
      },
    ];
  },
  beforeDestroy() {
    this.setGanadorFilter(null);
  },
};
</script>
<style scoped lang="scss">
.theme--light.v-application .text--secondary {
  color: #404a7a !important;
}

.v-application ul {
  padding-left: 2px !important;
}
</style>
